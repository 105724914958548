<template>
  <div class="live-wrap" v-if="intro">
    <div
      class="live-banner"
      :style="{backgroundImage:`url(${intro.coursePicUrl})`}"
    >
     <template v-if="live.liveStatus == 0">
        <div class="count_down count_down_time" id="dateShow" v-if="intro.liveStartTime">
          <p>直播倒计时</p>
          <span class="d"></span>天
          <span class="h"></span>小时
          <span class="m"></span>分
          <span class="date-s s"></span>秒
        </div>
        <div class="count_down" v-else>
          <span>{{intro.courseName && intro.courseName.substr(0, 38)}}</span>
        </div>
     </template>
     <template v-if="live.liveStatus == 1">
       <span class="count_down"><span>直播进行中</span></span>
     </template>
     <template v-if="live.liveStatus == 2" id="dateShow">
       <div class="count_down count_down_time">
            <p>直播倒计时</p>
            <span class="d"></span>天
            <span class="h"></span>小时
            <span class="m"></span>分
            <span class="date-s s"></span>秒
        </div>
     </template>
     <template v-if="live.liveStatus == 3">
        <span class="count_down live-end"><span>直播已结束</span></span>
     </template>
    </div>
    <div class="video-info">
      <div class="video-title">
        {{intro.courseName}}
        <!-- <div class="video-price">{{detail.Price}}</div> -->
      </div>
      <class-intro :intro="intro"></class-intro>
    </div>
    <menu-tab :menu="menu" :swiper="true" :slidesPerView="3" @change="selectMenu"></menu-tab>
    <div class="playback-author-warp" v-show="itemBool == 0">
        <class-sch-card :courseChapterList="courseChapterList"></class-sch-card>
    </div>
    <ul class="playback" v-show="itemBool == 1">
      <li class="play-video" v-for="item in RecList" :key="item.id">
        <router-link :to="{name: 'videoRealtime', query: { id: item.id, type: 0 }}">
          <img src="@/assets/images/icon-redbf.png" alt />
          <div class="video-detail">
            <div class="video-detail-title">{{ item.recordRoomName }}</div>
            <div class="video-detail-date">{{ dateFormat(item.recordStartTime) }}</div>
          </div>
          <class-sch-card :itemBool="itemBool" :courseChapterList="item.courseChapterVOList" :summaryBool="false"></class-sch-card>
        </router-link>
      </li>
    </ul>
    <class-comment :listComment="listComment" v-show="itemBool == 2"></class-comment>
    <class-footer :isBack="false" v-model="comment" @submit="subComment">
      <template v-slot:default>
        <router-link class="watch-back" :to="{name: 'videoRealtime', query: { id: RecList.length > 0 && RecList[0].id, type: 0 }}" v-if="live.liveStatus == 3">
          <span>观看回放</span>
        </router-link>
        <router-link class="watch-live" :to="{name: 'videoRealtime', query: { id: live.id, type: 1, vip: vip }}" v-else>
          <span>观看直播</span> 
        </router-link>
      </template>
    </class-footer>
  </div>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, inject, onUnmounted, watch } from "vue";
import ClassIntro from "@/components/common/ClassIntro.vue";
import MenuTab from "@/components/common/MenuTab.vue";
import ClassSchCard from "@/components/common/ClassSchCard.vue";
import ClassComment from "@/components/common/ClassComment.vue";
import ClassFooter from "@/components/common/ClassFooter.vue";
import Loading from "@/components/UI/Loading";

import api from '@/api/axios';
import { useRoute, useRouter } from "vue-router";
import dateFormat, { timeFormat } from "@/utils/dateFormat";
import leftTime, { clearTime } from "@/hooks/useLeftTime";
import axios from "@/api/axios";
import $ from 'jquery';
import qs from "qs";
import Toast from "@/components/UI/Toast";
import wx from "weixin-sdk-js";
export default defineComponent({
  name: "LiveDetail",
  components: {
    ClassIntro,
    MenuTab,
    ClassSchCard,
    ClassComment,
    ClassFooter,
    Loading
  },
  setup() {
    const shareTitle = ref('')
    const route = useRoute();
    const router = useRouter();
    const id = route.query.id;
    const intro = ref(null);
    const detail = ref({});
    const RecList = ref<object>([]);
    const courseChapterList = ref<object>([]);
    const live = ref({});
    const vip = ref(route.query.vip)
    //  切换列表菜单
    const menu = ref(["课程表", "回放目录(0)", "评论(0)"]);

    const itemBool = ref(0);
    function selectMenu(item: string) {
        console.log(item)
      if (item == "课程表") {
        itemBool.value = 0;
      }
      if (item.indexOf("回放目录") != -1) {
        itemBool.value = 1;
      }
      if (item.indexOf("评论") != -1) {
        itemBool.value = 2;
      }
      console.log(itemBool.value)
    }

    function contrast(startDate: string) {
      return new Date().getTime() - new Date(timeFormat(startDate)).getTime() < 0 ? true : false;
    }

    //评论
    const listComment = ref<object>([]);
    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    api.get("/M/Discover/LiveDetail/" + id).then(res => {
        const data = res.data.obj;
        intro.value = data.course;
        // document.title = data.course.courseName;
        detail.value = data;
        shareTitle.value = data.course.courseName
        document.title = data.course.courseName
        route.meta.title = data.course.courseName
        route.meta.content.description = data.course.courseDescription;
        // courseChapterList.value = data.courseChapterList;
        // RecList.value = data.RecList;
        // menu.value[1] = "回放目录(" + data.RecList.length + ")";
        // menu.value[2] = "评论(" + data.listComment.length + ")";
        // listComment.value = data.listComment;
        live.value = data.live;
        nextTick(() => {
          leftTime(data.course.liveStartTime, function (d: any) {
            if (d.status) {
                const $dateShow1 = $("#dateShow");
                $dateShow1.find(".d").html(d.d);
                $dateShow1.find(".h").html(d.h);
                $dateShow1.find(".m").html(d.m);
                $dateShow1.find(".s").html(d.s);
            }
          })
        })
    }).catch(err => {
        console.log(err)
    })

    api.get("/M/Discover//LiveDetailRelation/" + id).then(res => {
        const data = res.data.obj;
        // intro.value = data.course;
        // detail.value = data;
        courseChapterList.value = data.courseChapterList;
        RecList.value = data.RecList;
        menu.value[1] = "回放目录(" + data.RecList.length + ")";
        menu.value[2] = "评论(" + data.listComment.length + ")";
        listComment.value = data.listComment;
        // live.value = data.live;

        // nextTick(() => {
        //   leftTime(timeFormat(data.course.liveStartTime), function (d: any) {
        //     if (d.status) {
        //         const $dateShow1 = $("#dateShow");
        //         $dateShow1.find(".d").html(d.d);
        //         $dateShow1.find(".h").html(d.h);
        //         $dateShow1.find(".m").html(d.m);
        //         $dateShow1.find(".s").html(d.s);
        //     }
        //   })
        // })
    }).catch(err => {
        console.log(err)
    })

    const comment = ref("");
    const user = inject("userInfo") as object;
    function subComment() {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        id,
        content: comment.value,
        type: 0
      });
      axios
        .post("/M/Server/DoCourseComment", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            (listComment.value as object[]).unshift({
              ...res.data.obj,
              isAlreadyZan: 0,
              user: {
                ...user,
                userImg: (user as any).userImg,
              },
            });
            menu.value[2] = "评论(" + (listComment.value as object[]).length + ")";
            comment.value = "";
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
            router.push({name: "Login"})
          }
        })
        .catch((err) => {
       
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }

    watch(
      () => {
        return shareTitle.value
      }, 
      (to, from) => {
        const isweixin = ref(false);
        const ua = navigator.userAgent.toLowerCase();
        const reg = /MicroMessenger/i;
        isweixin.value = reg.test(ua);
        if (isweixin.value) {
          nextTick(() => {
            const link = window.location.href;
            const title = document.title;
            const desc = (document.querySelectorAll(
              "meta[name=description]"
            )[0] as any).content;
            console.log(desc);
            
            const formData = new FormData();
            formData.append("url", link);
            axios.post("/M/Server/getweixin", formData).then((res: any) => {
              const data = res.data;
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appid, // 必填，公众号的唯一标识
                timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.str, // 必填，签名
                jsApiList: [
                  "checkJsApi",
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                ], // 必填，需要使用的JS接口列表
              });
              wx.ready(function () {
                //需在用户可能点击分享按钮前就先调用

                wx.updateAppMessageShareData({
                  title: to+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
                wx.updateTimelineShareData({
                  title: to+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
              });
            });
          });
        }
      }
    )

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.name;
        },
      ],
      (to, from) => {
        //  只有直播详情跳直播播放的时候才会监控路由加载数据
        if (to[1] == "videoRealtime") {
          route.meta.title = (intro.value as any).courseName
          route.meta.content.description = (intro.value as any).courseDescription;
        }
      }
    );

    onUnmounted(() => {
      clearTime()
    })

    return {
      intro,
      menu,
      selectMenu,
      itemBool,
      listComment,
      detail,
      courseChapterList,
      dateFormat,
      RecList,
      contrast,
      timeFormat,
      live,
      comment,
      subComment,
      loadOption,
      vip
    };
  }
});
</script>

<style lang="scss" scoped>
.watch-back, .watch-live{
  color: #fff;
}
.live-wrap {
    width: 100%;
    padding-bottom: 113px;
}

.playback-author-warp {
    padding: 0 0.267rem;
}

.live-banner {
    width: 100%;
    height: 200px;
    background-size: cover;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
}

.count_down {
    font-size: 14px;
    text-align: center;
    line-height: 15px;
    color: #fff;
    margin-top: -13px;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    padding: 5px 0;
    box-sizing: border-box;
}

.count_down span{
    display: inline-block;
    color: yellow;
    cursor: pointer;
    font-size: 15px;
    margin: 4px auto;
}

.video-info {
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    padding: 0.573rem 0.267rem 0.267rem 0.267rem;
    overflow: hidden;
}

.video-title {
    width: 100%;
    padding-right: 2.667rem;
    box-sizing: border-box;
    color: #444444;
    font-size: 0.427rem;
    position: relative;

    font-weight: bold;
}

.video-price {
    width: 2.667rem;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    color: #b9135a;
    font-size: 0.4rem;
}

.playback li {
    position: relative;

    border-bottom: 1px solid #eeeeee;
    padding: 0.467rem 0.267rem 0.533rem 0.267rem;
    padding-left: 1.6rem;
    box-sizing: border-box;
    overflow: hidden;

}

.playback li > a > img {
    width: 0.933rem;
    height: 0.933rem;
    position: absolute;
    left: 0.267rem;
    top: 0.56rem;
}

.video-detail-title {
    color: #444444;
    font-size: 0.426rem;
    /*width: 6rem;*/
}

.video-detail-date {
    width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999999;
    font-size: 0.373rem;
    margin-top: 0.267rem;
}
  .count_down span{
        color: yellow;
  }
  
</style>