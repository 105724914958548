
import { defineComponent, ref, nextTick, inject, onUnmounted, watch } from "vue";
import ClassIntro from "@/components/common/ClassIntro.vue";
import MenuTab from "@/components/common/MenuTab.vue";
import ClassSchCard from "@/components/common/ClassSchCard.vue";
import ClassComment from "@/components/common/ClassComment.vue";
import ClassFooter from "@/components/common/ClassFooter.vue";
import Loading from "@/components/UI/Loading";

import api from '@/api/axios';
import { useRoute, useRouter } from "vue-router";
import dateFormat, { timeFormat } from "@/utils/dateFormat";
import leftTime, { clearTime } from "@/hooks/useLeftTime";
import axios from "@/api/axios";
import $ from 'jquery';
import qs from "qs";
import Toast from "@/components/UI/Toast";
import wx from "weixin-sdk-js";
export default defineComponent({
  name: "LiveDetail",
  components: {
    ClassIntro,
    MenuTab,
    ClassSchCard,
    ClassComment,
    ClassFooter,
    Loading
  },
  setup() {
    const shareTitle = ref('')
    const route = useRoute();
    const router = useRouter();
    const id = route.query.id;
    const intro = ref(null);
    const detail = ref({});
    const RecList = ref<object>([]);
    const courseChapterList = ref<object>([]);
    const live = ref({});
    const vip = ref(route.query.vip)
    //  切换列表菜单
    const menu = ref(["课程表", "回放目录(0)", "评论(0)"]);

    const itemBool = ref(0);
    function selectMenu(item: string) {
        console.log(item)
      if (item == "课程表") {
        itemBool.value = 0;
      }
      if (item.indexOf("回放目录") != -1) {
        itemBool.value = 1;
      }
      if (item.indexOf("评论") != -1) {
        itemBool.value = 2;
      }
      console.log(itemBool.value)
    }

    function contrast(startDate: string) {
      return new Date().getTime() - new Date(timeFormat(startDate)).getTime() < 0 ? true : false;
    }

    //评论
    const listComment = ref<object>([]);
    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    api.get("/M/Discover/LiveDetail/" + id).then(res => {
        const data = res.data.obj;
        intro.value = data.course;
        // document.title = data.course.courseName;
        detail.value = data;
        shareTitle.value = data.course.courseName
        document.title = data.course.courseName
        route.meta.title = data.course.courseName
        route.meta.content.description = data.course.courseDescription;
        // courseChapterList.value = data.courseChapterList;
        // RecList.value = data.RecList;
        // menu.value[1] = "回放目录(" + data.RecList.length + ")";
        // menu.value[2] = "评论(" + data.listComment.length + ")";
        // listComment.value = data.listComment;
        live.value = data.live;
        nextTick(() => {
          leftTime(data.course.liveStartTime, function (d: any) {
            if (d.status) {
                const $dateShow1 = $("#dateShow");
                $dateShow1.find(".d").html(d.d);
                $dateShow1.find(".h").html(d.h);
                $dateShow1.find(".m").html(d.m);
                $dateShow1.find(".s").html(d.s);
            }
          })
        })
    }).catch(err => {
        console.log(err)
    })

    api.get("/M/Discover//LiveDetailRelation/" + id).then(res => {
        const data = res.data.obj;
        // intro.value = data.course;
        // detail.value = data;
        courseChapterList.value = data.courseChapterList;
        RecList.value = data.RecList;
        menu.value[1] = "回放目录(" + data.RecList.length + ")";
        menu.value[2] = "评论(" + data.listComment.length + ")";
        listComment.value = data.listComment;
        // live.value = data.live;

        // nextTick(() => {
        //   leftTime(timeFormat(data.course.liveStartTime), function (d: any) {
        //     if (d.status) {
        //         const $dateShow1 = $("#dateShow");
        //         $dateShow1.find(".d").html(d.d);
        //         $dateShow1.find(".h").html(d.h);
        //         $dateShow1.find(".m").html(d.m);
        //         $dateShow1.find(".s").html(d.s);
        //     }
        //   })
        // })
    }).catch(err => {
        console.log(err)
    })

    const comment = ref("");
    const user = inject("userInfo") as object;
    function subComment() {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        id,
        content: comment.value,
        type: 0
      });
      axios
        .post("/M/Server/DoCourseComment", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            (listComment.value as object[]).unshift({
              ...res.data.obj,
              isAlreadyZan: 0,
              user: {
                ...user,
                userImg: (user as any).userImg,
              },
            });
            menu.value[2] = "评论(" + (listComment.value as object[]).length + ")";
            comment.value = "";
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
            router.push({name: "Login"})
          }
        })
        .catch((err) => {
       
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }

    watch(
      () => {
        return shareTitle.value
      }, 
      (to, from) => {
        const isweixin = ref(false);
        const ua = navigator.userAgent.toLowerCase();
        const reg = /MicroMessenger/i;
        isweixin.value = reg.test(ua);
        if (isweixin.value) {
          nextTick(() => {
            const link = window.location.href;
            const title = document.title;
            const desc = (document.querySelectorAll(
              "meta[name=description]"
            )[0] as any).content;
            console.log(desc);
            
            const formData = new FormData();
            formData.append("url", link);
            axios.post("/M/Server/getweixin", formData).then((res: any) => {
              const data = res.data;
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appid, // 必填，公众号的唯一标识
                timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.str, // 必填，签名
                jsApiList: [
                  "checkJsApi",
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                ], // 必填，需要使用的JS接口列表
              });
              wx.ready(function () {
                //需在用户可能点击分享按钮前就先调用

                wx.updateAppMessageShareData({
                  title: to+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
                wx.updateTimelineShareData({
                  title: to+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
              });
            });
          });
        }
      }
    )

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.name;
        },
      ],
      (to, from) => {
        //  只有直播详情跳直播播放的时候才会监控路由加载数据
        if (to[1] == "videoRealtime") {
          route.meta.title = (intro.value as any).courseName
          route.meta.content.description = (intro.value as any).courseDescription;
        }
      }
    );

    onUnmounted(() => {
      clearTime()
    })

    return {
      intro,
      menu,
      selectMenu,
      itemBool,
      listComment,
      detail,
      courseChapterList,
      dateFormat,
      RecList,
      contrast,
      timeFormat,
      live,
      comment,
      subComment,
      loadOption,
      vip
    };
  }
});
